import { FC, useEffect, useMemo, useState } from 'react';
import {
  ContextHelpSidePanelBodyId,
  ContextHelpSidePanelId,
  MENU_OPENED_KEY,
  MENU_PINNED_KEY,
  OpenCloseValues,
} from 'frontend-container/components/ContextHelp/constants';
import {
  closeContextHelp,
  isOpenedByUser,
} from 'frontend-container/components/ContextHelp/utils/closeHelper';
import { getCSHID } from 'frontend-container/components/ContextHelp/utils/getCSHID';
import {
  getHelpUrl,
  openHelpTopic,
} from 'frontend-container/components/ContextHelp/utils/openHelpTopic';
import {
  checkIsAlreadyPinned,
  checkIsPinAvailable,
  checkIsPinnedByUser,
  pinContextHelp,
  pinContextHelpOnUserAction,
  unpinContextHelp,
  unpinContextHelpOnUserAction,
} from 'frontend-container/components/ContextHelp/utils/pinningHelpers';
import { ExternalPageIFrame } from 'frontend-container/components/ExternalPageIFrame/ExternalPageIFrame';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import i18next from 'i18next';
import debounce from 'lodash.debounce';

import { repeatableCall } from '@ac/library-utils/dist/utils';
import {
  AlignItems,
  ButtonPattern,
  ButtonTheme,
  HeaderTheme,
  IconName,
  JustifyContent,
  LayoutProperties,
  SidePanelLayer,
  SidePanelSide,
  SidePanelSize,
  TargetValueObject,
  TextColor,
  TextSize,
} from '@ac/web-components';

import './ContextHelpSidePanel.scss';

const OFFSET = { distanceFromTarget: -65, shiftFromTheMiddle: 0 };

export const ContextHelpSidePanel: FC = () => {
  const [topicToOpen, setTopicToOpen] = useState('');
  const [_, forceUpdate] = useState(new Date().valueOf());
  const [isPinnedState, setIsPinnedState] = useState(checkIsPinnedByUser());
  const [isVisible, setIsVisible] = useState(isOpenedByUser());

  const eventBus = useMemo(() => getCurrentGlobalEventBus(), []);

  useEffect(() => {
    if (isVisible) {
      openHelpTopic(eventBus, topicToOpen);
      if (isPinnedState) {
        pinContextHelp(eventBus);
      }
    }
  }, [isVisible, isPinnedState, topicToOpen, eventBus]);

  const onCloseContextHelp = (): void => {
    closeContextHelp(eventBus);
    setIsVisible(false);
  };

  const onOpenInNewTab = async (): Promise<void> => {
    const url = getHelpUrl(await getCSHID(undefined, true));
    if (url) {
      window.open(url, '_blank');
    }
  };

  const onOpenContextHelpFromExternal = (topicToOpenValue: string): void => {
    setTopicToOpen(topicToOpenValue);
    setIsVisible(true);
    onResize(true);
  };

  const onUpdateContextHelpFromExternal = (topicToOpenValue: string): void => {
    setTopicToOpen(topicToOpenValue);
    openHelpTopic(eventBus, topicToOpen);
  };

  const updateHelp = async (): Promise<void> => {
    if (sessionStorage.getItem(MENU_OPENED_KEY) === OpenCloseValues.Open) {
      openHelpTopic(eventBus);
    }
    await repeatableCall(
      () => new Promise((resolve) => resolve(true)),
      () => !!document.querySelector('.ac-breadcrumbs-navigation-current-page'),
      {
        intervalTime: 200,
        repeatCount: 50,
      }
    );
  };

  const onPinContextHelp = (): void => {
    if (checkIsAlreadyPinned()) {
      unpinContextHelpOnUserAction(eventBus);
      setIsPinnedState(false);
    } else {
      pinContextHelpOnUserAction(eventBus);
      setIsPinnedState(true);
    }
  };

  const isPinAvailable = checkIsPinAvailable();

  const onResize = (forced: boolean = false): void => {
    const isClosed =
      sessionStorage.getItem(MENU_OPENED_KEY) === OpenCloseValues.Closed ||
      sessionStorage.getItem(MENU_PINNED_KEY) === null;

    if (isClosed && !forced) {
      return;
    }

    const isLargeScreen = checkIsPinAvailable();
    const isAlreadyPinned = checkIsAlreadyPinned();
    const isAlreadyNotPinned = !isAlreadyPinned;
    const shouldPin =
      isAlreadyNotPinned &&
      isLargeScreen &&
      (sessionStorage.getItem(MENU_PINNED_KEY) === OpenCloseValues.Open ||
        sessionStorage.getItem(MENU_PINNED_KEY) === null);
    const shouldUnPin = isAlreadyPinned && !isLargeScreen;

    if (shouldPin) {
      pinContextHelp(eventBus);
    }
    if (shouldUnPin) {
      unpinContextHelp(eventBus);
    }

    forceUpdate(new Date().valueOf());
  };
  const onResizeDebounced = debounce(() => onResize());

  useEffect(() => {
    window.addEventListener('popstate', updateHelp);
    window.addEventListener('resize', onResizeDebounced);
    (
      window.ACP?.container ?? { openContextHelpTopic: undefined }
    ).openContextHelpTopic = onOpenContextHelpFromExternal;
    (
      window.ACP?.container ?? { updateContextHelpTopic: undefined }
    ).updateContextHelpTopic = onUpdateContextHelpFromExternal;
    (
      window.ACP?.container ?? { closeContextHelpPanel: undefined }
    ).closeContextHelpPanel = onCloseContextHelp;

    return (): void => {
      window.removeEventListener('popstate', updateHelp);
      window.removeEventListener('resize', onResizeDebounced);
      (
        window.ACP?.container ?? { openContextHelpTopic: undefined }
      ).openContextHelpTopic = undefined;
      (
        window.ACP?.container ?? { updateContextHelpTopic: undefined }
      ).updateContextHelpTopic = undefined;
      (
        window.ACP?.container ?? { closeContextHelpPanel: undefined }
      ).closeContextHelpPanel = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ac-side-panel
      id={ContextHelpSidePanelId}
      class="context-help"
      overlay={false}
      side={SidePanelSide.right}
      layer={SidePanelLayer.first}
      size={SidePanelSize.sm}
      visible={isVisible}
    >
      <ac-layout layout={LayoutProperties.headerBody}>
        <ac-layout-item area="header">
          <ac-header
            theme={HeaderTheme.primaryDark}
            justifyContent={JustifyContent.spaceBetween}
            alignItems={AlignItems.center}
          >
            <ac-text
              class="ac-spacing-left-lg"
              size={TextSize.h3}
              color={TextColor.white}
            >
              {i18next.t('CONTEXT_HELP.TITLE')}
            </ac-text>
            <ac-box sizeSm="auto">
              <ac-button
                id="context-help-new-tab"
                pattern={ButtonPattern.tertiary}
                theme={ButtonTheme.light}
                onClick={onOpenInNewTab}
                data-test-selector={'context-help-new-tab'}
              >
                <ac-icon icon={IconName.openInNewTab} />
              </ac-button>
              {isPinAvailable && (
                <ac-button
                  id="context-help-pin"
                  pattern={ButtonPattern.tertiary}
                  theme={ButtonTheme.light}
                  onClick={onPinContextHelp}
                  data-test-selector={'context-help-pin'}
                >
                  <ac-icon
                    icon={isPinnedState ? IconName.unpin : IconName.pin}
                  />
                </ac-button>
              )}
              <ac-button
                id="context-help-close"
                pattern={ButtonPattern.tertiary}
                theme={ButtonTheme.light}
                onClick={onCloseContextHelp}
                data-test-selector={'context-help-close'}
              >
                <ac-icon icon={IconName.cancel} />
              </ac-button>
              <ac-tooltip
                for="#context-help-new-tab"
                attachTo="#context-help-new-tab"
                targetValue={TargetValueObject.sidePanel}
                offset={OFFSET}
                text={i18next.t('CONTEXT_HELP.OPEN_IN_NEW_TAB')}
              />
              <ac-tooltip
                for="#context-help-pin"
                attachTo="#context-help-pin"
                targetValue={TargetValueObject.sidePanel}
                offset={OFFSET}
                text={
                  isPinnedState
                    ? i18next.t('CONTEXT_HELP.UNPIN')
                    : i18next.t('CONTEXT_HELP.PIN')
                }
              />
              <ac-tooltip
                for="#context-help-close"
                attachTo="#context-help-close"
                targetValue={TargetValueObject.sidePanel}
                offset={OFFSET}
                text={i18next.t('COMMON.BUTTONS.CLOSE')}
              />
            </ac-box>
          </ac-header>
        </ac-layout-item>
        <ac-layout-item area="body">
          <ExternalPageIFrame name={ContextHelpSidePanelBodyId} />
        </ac-layout-item>
      </ac-layout>
    </ac-side-panel>
  );
};
