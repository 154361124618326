import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { getCroItems } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyUnits } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import {
  getUserUnitsDetails,
  UserUnitsDetails,
} from 'frontend-container/components/Prestep/getUserUnitsDetails';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import {
  createReactRoot,
  removeReactRoot,
} from 'frontend-container/utils/createReactRoot';
import { isLoginUrl, isMainUrl } from 'frontend-container/utils/location';

import { UserPreferencesDetails } from '@ac/library-api';

import { PrestepView } from './PrestepView';

const getIsUserWithUnsetPreferences = (
  {
    isMultiCroUser,
    isMultiPropertyUser,
    isUserWithoutCroUnits,
    isUserWithoutProperties,
  }: UserUnitsDetails,
  userPreferences?: UserPreferencesDetails
): boolean => {
  const hasCroAndProperty = !isUserWithoutProperties && !isUserWithoutCroUnits;

  if (hasCroAndProperty) {
    if (!userPreferences?.defaultWorkspace) {
      return true;
    }
  }

  const hasOnlyProperties = isMultiPropertyUser && isUserWithoutCroUnits;
  const hasPropertyWorkspaceAndProperties =
    isMultiPropertyUser &&
    userPreferences?.defaultWorkspace === ContextType.PROPERTY;

  if (hasOnlyProperties || hasPropertyWorkspaceAndProperties) {
    if (!userPreferences?.defaultPropertyId) {
      return true;
    }
  }

  const hasOnlyCros = isMultiCroUser && isUserWithoutProperties;
  const hasCroWorkspaceAndCros =
    isMultiCroUser && userPreferences?.defaultWorkspace === ContextType.CRO;

  if (hasOnlyCros || hasCroWorkspaceAndCros) {
    if (!userPreferences?.defaultCentralReservationOfficeId) {
      return true;
    }
  }

  return false;
};

export const getShouldRedirectToPrestep = (
  pathname: string = window.location.pathname
): boolean => {
  const hasPathnameFits = isMainUrl(pathname) || isLoginUrl(pathname);

  if (!hasPathnameFits) {
    return false;
  }

  const preferences = userService.getCurrentUserPreferences();
  const preferencesDetails = preferences?.preferences;

  const croUnits = getCroItems();
  const properties = getPropertyUnits();

  const userUnitsDetails = getUserUnitsDetails(properties, croUnits);

  const isUserWithUnsetPreferences = getIsUserWithUnsetPreferences(
    userUnitsDetails,
    preferencesDetails
  );

  if (
    userUnitsDetails.isUserWithoutCroUnits &&
    userUnitsDetails.isUserWithoutProperties
  ) {
    return false;
  }

  if (
    userUnitsDetails.isSinglePropertyUser &&
    userUnitsDetails.isUserWithoutCroUnits
  ) {
    return false;
  }

  if (
    userUnitsDetails.isSingleCroUser &&
    userUnitsDetails.isUserWithoutProperties
  ) {
    return false;
  }

  if (!isUserWithUnsetPreferences) {
    return false;
  }

  return true;
};

export const initPrestep = async (
  route: string,
  callback: (
    route: string,
    userPreferences?: UserPreferencesDetails
  ) => Promise<void>
): Promise<void> => {
  removeReactRoot(ROUTER_OUTLET_SELECTOR);
  const containerRoot = createReactRoot(ROUTER_OUTLET_SELECTOR);

  containerRoot.render(
    <PrestepView
      onConfirm={(userPreferences): Promise<void> =>
        callback(route, userPreferences)
      }
    />
  );
};
