import { Api } from 'frontend-container/publicApi';
import { getUnitForCurrentLocation } from 'frontend-container/shared/businessContext/getUnitForCurrentLocation';

import { BusinessContextUnitType, WebSocketFactory } from '@ac/library-api';
import { getGlobalACPNamespace } from '@ac/library-utils/dist/utils';

export const setUpWebSocketsApi = (): void => {
  const acp = getGlobalACPNamespace();
  const api = (acp.api ?? { webSockets: undefined }) as Api;

  const unit = getUnitForCurrentLocation();

  if (unit.propertyId) {
    api.webSockets = new WebSocketFactory().create({
      unitType: BusinessContextUnitType.Property,
      unitId: unit.propertyId,
      baseWebSocket: api.webSockets,
    });
  } else if (unit.centralReservationOfficeId) {
    api.webSockets = new WebSocketFactory().create({
      unitType: BusinessContextUnitType.CentralReservationOffice,
      baseWebSocket: api.webSockets,
    });
  } else if (unit.tenantId) {
    api.webSockets = new WebSocketFactory().create({
      unitType: BusinessContextUnitType.Tenant,
      baseWebSocket: api.webSockets,
    });
  } else {
    /** noop **/
  }
};
