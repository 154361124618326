import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  activityReservationsCustomerPermissionKeys,
  ActivityReservationsCustomerSettingsKeys,
  activityReservationsPropertyPermissionKeys,
  FeatureToggleName,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const activityReservationsMenu: MenuElement = {
  translation: 'MENU.ACTIVITY_RESERVATIONS.TITLE',
  icon: IconName.spa,
  id: 'menu-activity-reservations',
  featureToggles: [FeatureToggleName.ActivityReservations],
  settings: [ActivityReservationsCustomerSettingsKeys.ActivityReservations],
  items: [
    {
      permissionsConfiguration: {
        permissions: [
          activityReservationsCustomerPermissionKeys.viewActivitiesDashboard,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      link: `${acConfig.newFrontendUrls.activityReservations}/dashboard`,
      translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.DASHBOARD',
    },
    {
      permissionsConfiguration: {
        permissions: [
          activityReservationsPropertyPermissionKeys.viewActivitiesPlanner,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      link: `${acConfig.newFrontendUrls.activityReservations}/planner`,
      translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.PLANNER',
    },
    {
      permissionsConfiguration: {
        permissions: [
          activityReservationsPropertyPermissionKeys.createAndEditActivityReservation,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      link: `${acConfig.newFrontendUrls.profiles}/prestep/activity-reservations`,
      translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.CREATE_RESERVATION',
      aliases: [
        `${acConfig.newFrontendUrls.activityReservations}/reservations/new`,

        // deprecated
        `${acConfig.newFrontendUrls.reservations}/profiles/prestep?originModule=ActivityReservations`,
        //
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.activityConfiguration}/activity-manager`,
      translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.ACTIVITY_MANAGER',
    },
    {
      link: `${acConfig.newFrontendUrls.activityConfiguration}/rate-manager`,
      translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.RATE_MANAGER',
      permissionsConfiguration: {
        permissions: [
          activityReservationsPropertyPermissionKeys.viewActivitiesRateManager,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
