import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage';
import { SwitchToContextType } from 'frontend-container/components/Menu/components/Context/components/SwitchToContextType';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import {
  getCroContexts,
  isCroEnabled,
  redirectToPropertyOrNoPermissionsPage,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { PropertySelectorReadOnly } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/PropertySelectorReadOnly';
import { isPropertySelectorReadOnlyVisible } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/visibility';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { redirectIfContextRegionChanged } from 'frontend-container/utils/region/redirectIfContextRegionChanged';
import { redirectToProperRegionIfNecessary } from 'frontend-container/utils/region/redirectToProperRegionIfNecessary';
import { isEmpty } from 'lodash';

import { BusinessContextUnitIdentifier } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { getGlobalRegionCode } from '@ac/library-utils/dist/utils/multi-region';
import { IconName } from '@ac/web-components';

import { Context, ContextComponent } from '../Context';

import { useCroDateTime } from './useCroDateTime';

export const CroContext = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contexts, setContexts] = useState<Context[]>([]);
  const [selected, setSelected] = useState<Context | undefined>(undefined);
  const { propertyId } = useSessionContextTypeIds();
  const { t } = useTranslation();

  const initialize = async (): Promise<void> => {
    const tenantId = LoginService.authData()?.tenantId;

    if (!tenantId) {
      throw new Error('Tenant ID is undefined');
    }

    setIsLoading(true);

    const croContexts = getCroContexts();
    const propertyContexts = getPropertyContexts();

    if (isEmpty(croContexts) || !isCroEnabled()) {
      await redirectToPropertyOrNoPermissionsPage(propertyContexts.length);

      return;
    }

    setContexts(croContexts);

    const croSelected = selectInitialContext(
      croContexts,
      SessionService.getCentralReservationOfficeId()
    );

    if (croSelected) {
      await selectUnitInBusinessContext({
        tenantId,
        centralReservationOfficeId: croSelected.id,
      });
    }

    setSelected(croSelected);
    setSessionContext(croSelected);

    const region = findRegionData(
      croSelected?.regionCode ?? getGlobalRegionCode()
    );
    redirectToProperRegionIfNecessary(region, croSelected, croContexts);
    setIsLoading(false);
  };

  const onSelect = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSelected(context);
    setSessionContext(context);

    const redirected = redirectIfContextRegionChanged(context);

    if (!redirected && tenantId) {
      void selectUnitInBusinessContext({
        tenantId,
        centralReservationOfficeId: context.id,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', croLandingPagePathname);
      }
    }
  };

  useEffect(() => {
    void initialize();
  }, []);

  const targetPropertyUnit: BusinessContextUnitIdentifier | undefined =
    useMemo(() => {
      const propertyContexts = getPropertyContexts();
      const canSwitchToProperty = propertyContexts.length > 0;
      const targetPropertyId = propertyId ?? propertyContexts[0]?.id;

      if (!canSwitchToProperty || !targetPropertyId) {
        return undefined;
      }

      const tenantId = LoginService.authData()?.tenantId;

      return {
        propertyId: targetPropertyId,
        tenantId,
      };
    }, [propertyId]);

  return isLoading ? (
    <ac-loader-covering />
  ) : (
    <>
      {targetPropertyUnit && (
        <SwitchToContextType
          title={t('MENU.CONTEXT.SWITCH_TO_PROPERTY')}
          route={propertyLandingPagePathname}
          icon={IconName.configProperty}
          targetUnit={targetPropertyUnit}
        />
      )}
      {isPropertySelectorReadOnlyVisible() && <PropertySelectorReadOnly />}
      <ContextComponent
        contextList={contexts}
        selected={selected}
        onSelect={onSelect}
        useDateTime={useCroDateTime}
        dateLabel={t('MENU.CONTEXT.CRO.DATE')}
        timeLabel={t('MENU.CONTEXT.CRO.TIME')}
      />
    </>
  );
};
