import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { activityReservationsMenu } from 'frontend-container/components/Menu/configuration/activityReservations';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/centralAvailabilityInquiry';
import { croStructureMenu } from 'frontend-container/components/Menu/configuration/croStructure';
import { inquiryMenu } from 'frontend-container/components/Menu/configuration/inquiry';
import { ratesMenu } from 'frontend-container/components/Menu/configuration/rates';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/reservationsLookup';
import { resourceManagementMenu } from 'frontend-container/components/Menu/configuration/resourceManagement';
import { resourceManagement2Menu } from 'frontend-container/components/Menu/configuration/resourceManagement2';
import { salesActivitiesMenu } from 'frontend-container/components/Menu/configuration/salesActivities';
import { getReportingMenuItems } from 'frontend-container/components/Menu/configuration/utils/getReportingMenuItems';
import { workflowsMenu } from 'frontend-container/components/Menu/configuration/workflows';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';

import { accountsReceivableMenu } from './accountsReceivable';
import { applicationDashboardsMenu } from './application';
import { cashieringMenu } from './cashiering';
import { changelogMenu } from './changelog';
import { commissionsMenu } from './commissions';
import {
  configurationMenu,
  getConfigurationMenuInMainApplication,
} from './configuration';
import { customersMenuFull, customersMenuReadOnlyMode } from './customers';
import { endOfDayMenu } from './endOfDay';
import { frontdeskMenu } from './frontdesk';
import { groupsMenu } from './groups';
import { housekeepingMenu } from './housekeeping';
import { integrationsMenu } from './integrations';
import { meetingsAndEventsMenu } from './meetingsAndEvents';
import { organizationStructureMenu } from './organizationStructure';
import { profilesMenu } from './profiles';
import { reportsMenu } from './reports';
import { reservationsMenu } from './reservations';
import { systemUsersMenu } from './systemUsers';
import { taskManagementMenu } from './taskManagement';
import { usersMenu } from './users';

export const menuItemHeightInPx = 42;
const menuHeaderHeightInPx = 32;

const fillShiftForMenuElement = (menuElement: MenuElement): MenuElement => {
  return {
    ...menuElement,
    shift:
      (menuItemHeightInPx * menuElement.items.length + menuHeaderHeightInPx) /
        2 -
      menuHeaderHeightInPx / 2,
  };
};

export const getFullMenu = (): MenuElement[] => [
  organizationStructureMenu,
  croStructureMenu,
  reservationsLookupMenu,
  centralAvailabilityInquiryMenu,
  usersMenu,
  integrationsMenu,
  ...getMainApplicationMenu(),
  applicationDashboardsMenu,
];

export const getMainApplicationMenu = (): MenuElement[] =>
  [
    inquiryMenu,
    profilesMenu,
    reservationsMenu,
    activityReservationsMenu,
    resourceManagement2Menu,
    ratesMenu,
    groupsMenu,
    frontdeskMenu,
    cashieringMenu,
    housekeepingMenu,
    taskManagementMenu,
    {
      ...reportsMenu,
      items: [...reportsMenu.items, ...getReportingMenuItems()],
    },
    endOfDayMenu,
    meetingsAndEventsMenu,
    salesActivitiesMenu,
    accountsReceivableMenu,
    commissionsMenu,
    integrationsMenu,
    changelogMenu,
    resourceManagementMenu,
    getConfigurationMenuInMainApplication(),
  ].map(fillShiftForMenuElement);

export const getAllowedMainApplicationMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] =>
  getAllowedMenuItems(getMainApplicationMenu(), configuration);

export const getAllowedConfigurationMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  let menuElements: MenuElement[];

  if (LoginService.isSuperUser()) {
    menuElements = [
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
      systemUsersMenu,
      changelogMenu,
    ];
  } else {
    menuElements = [
      organizationStructureMenu,
      croStructureMenu,
      usersMenu,
      integrationsMenu,
      applicationDashboardsMenu,
    ];
  }

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

export const getAllowedWorkflowsMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [
    workflowsMenu,
    changelogMenu,
    configurationMenu,
    applicationDashboardsMenu,
  ];

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

export const getAllowedChangelogMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  let menuElements: MenuElement[];

  if (LoginService.isSuperUser()) {
    menuElements = [
      changelogMenu,
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
      systemUsersMenu,
    ];
  } else {
    menuElements = [
      changelogMenu,
      configurationMenu,
      applicationDashboardsMenu,
    ];
  }

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

export const getAllowedCentralReservationOfficeMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [
    centralAvailabilityInquiryMenu,
    reservationsLookupMenu,
    getConfigurationMenuInMainApplication(),
  ];

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};
