import React, {
  FC,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { pathname403 } from 'frontend-container/components/Errors/Error403';
import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { getIsMenuComponentAllowed } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Arrows } from 'frontend-container/components/Menu/components/Arrows';
import { Cashier } from 'frontend-container/components/Menu/components/Cashier/Cashier';
import { isCashierVisible } from 'frontend-container/components/Menu/components/Cashier/visibility';
import {
  CroContext,
  isCroContextVisible,
} from 'frontend-container/components/Menu/components/CroContext';
import { HorizontalBar } from 'frontend-container/components/Menu/components/HorizontalBar/HorizontalBar';
import { Item } from 'frontend-container/components/Menu/components/Item';
import { Logo } from 'frontend-container/components/Menu/components/Logo';
import {
  isBellVisible,
  NotificationBellContainer,
} from 'frontend-container/components/Menu/components/NotificationBell';
import {
  isPropertyContextVisible,
  PropertyContext,
} from 'frontend-container/components/Menu/components/PropertyContext';
import { TrainingBar } from 'frontend-container/components/Menu/components/TrainingBar';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { User } from 'frontend-container/components/Menu/components/User/User';
import { useIsWorkstationVisible } from 'frontend-container/components/Menu/components/Workstation/visibility';
import { WorkstationMenu } from 'frontend-container/components/Menu/components/Workstation/Workstation';
import {
  getAllowedCentralReservationOfficeMenu,
  getAllowedChangelogMenu,
  getAllowedConfigurationMenu,
  getAllowedMainApplicationMenu,
  getAllowedWorkflowsMenu,
  getFullMenu,
} from 'frontend-container/components/Menu/configuration';
import {
  isBusinessContextDataGoingToChange,
  shouldUseNewMenu,
} from 'frontend-container/components/Menu/service';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { useTrainingFeature } from 'frontend-container/components/Menu/useTrainingFeature';
import {
  adjustScrollToCurrentMenuItem,
  endIndex,
  getNewStartEndIndexOnScrollDown,
  setEndIndex,
  setStartIndex,
  startIndex,
} from 'frontend-container/components/Menu/utils/adjustScrollToCurrentMenuItem';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isModuleWithoutMainMenuEntry } from 'frontend-container/components/Menu/utils/isModuleWithoutMainMenuEntry';
import { isPathnameWithoutProperty } from 'frontend-container/components/Menu/utils/isPathnameWithoutProperty';
import { isCentralReservationOfficeModule } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import {
  isChangelogModule,
  isChangelogModuleForSuperAdmin,
} from 'frontend-container/components/Menu/utils/modules/changelog';
import {
  isConfigurationModule,
  isConfigurationModuleForSuperAdmin,
} from 'frontend-container/components/Menu/utils/modules/configuration';
import {
  isConfigurationv2ModuleForSuperAdmin,
  isConfigurationv2TenantContextModule,
} from 'frontend-container/components/Menu/utils/modules/configurationv2';
import {
  isNotificationsTenantContextDashboard,
  isNotificationSubscriptions,
} from 'frontend-container/components/Menu/utils/modules/notifications';
import { isWorkflowsModule } from 'frontend-container/components/Menu/utils/modules/workflows';
import { scrollToSelectedMenuItem } from 'frontend-container/components/Menu/utils/scrollToSelectedMenuItem';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  getReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import { emberPathNameAppNameMap } from 'frontend-container/config/emberPathnameAppNameMap';
import { businessContextUpdatedEventCreator } from 'frontend-container/publicApi/events';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { getAppScopeFromPathname } from 'frontend-container/utils/getAppScopeFromPathname';
import { isEmberAppByAppName } from 'frontend-container/utils/isEmberApp';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';
import { navigateToUrl, unloadApplication } from 'single-spa';

import { LoginService } from '@ac/library-utils/dist/services';
import { AlignItems, FlexDirection, JustifyContent } from '@ac/web-components';

import { getAvailableMenuItemsCount, getSelectedMenuItem } from './menuItems';

import './Menu.scss';

let usedApp: string | undefined;
let resizeId: ReturnType<typeof setTimeout> | undefined;
export const Menu = (): JSX.Element => {
  const [menuItems, setMenuItems] = useState<MenuElement[]>([]);
  const [scrollDownDisabled, setScrollDownDisabled] = useState(false);
  const [scrollUpDisabled, setScrollUpDisabled] = useState(true);
  const [forcedCurrentIndex, forceUpdate] = useState<number>();
  const [forcedRecalculate, forcedRecalculateUpdate] = useState<Date>(
    new Date()
  );
  const locationRef = useRef<string>(location.pathname);
  const [selectedItem, setSelectedItem] = useState(() => {
    const [initialSelectedElement] = getSelectedMenuItem(menuItems);

    return initialSelectedElement?.id;
  });
  const [selectedRoute, setSelectedRoute] = useState(() => {
    const [, initialSelectedItem] = getSelectedMenuItem(menuItems);

    return initialSelectedItem ? initialSelectedItem.link : undefined;
  });
  const { isWorkstationVisible } = useIsWorkstationVisible();
  const useNew = shouldUseNewMenu();
  const isTrainingBarVisible = useTrainingFeature(selectedRoute ?? '');
  const fullMenuConfiguration = useMemo(() => getFullMenu(), []);

  useEffect(() => {
    void updateRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const eventBus = getCurrentGlobalEventBus();

    window.addEventListener('popstate', updateRoute);
    window.addEventListener('resize', handleResize);
    const unsubscribeBusinessContextUpdatedEvent = eventBus.subscribe(
      businessContextUpdatedEventCreator,
      () => updateRoute()
    );

    return (): void => {
      window.removeEventListener('popstate', updateRoute);
      window.removeEventListener('resize', handleResize);
      unsubscribeBusinessContextUpdatedEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  const recalculateItemsCount = (): void => {
    if (startIndex === undefined && endIndex === undefined) {
      const count = getAvailableMenuItemsCount(isPathnameWithoutProperty());
      if (!Number.isNaN(count) && count > 0) {
        setStartIndex(0);
        setEndIndex(count - 1);
        forceUpdate(endIndex);
      }
    }
  };

  const handleResize = (): void => {
    if (resizeId) {
      clearTimeout(resizeId);
    }
    resizeId = setTimeout(doneResizing, 150);
  };

  const doneResizing = async (): Promise<void> => {
    const currentMenuElements = menuItems;
    const [element] = getSelectedMenuItem(currentMenuElements);
    forcedRecalculateUpdate(new Date());
    const id = element?.id ?? selectedItem ?? '';
    if (id) {
      await adjustScrollToCurrentMenuItem(
        id,
        currentMenuElements,
        onScrollDown,
        onScrollUp
      );
      forcedRecalculateUpdate(new Date());
    }
  };

  const redirectWithoutMenuItemAccess = (
    allAccessConfiguration: AllAccessConfiguration,
    currentMenuElementSelected?: MenuElement
  ): boolean => {
    const isRouteNotFound =
      !currentMenuElementSelected &&
      allAccessConfiguration[allAccessConfiguration.currentSource].permissions
        .length &&
      !isModuleWithoutMainMenuEntry();

    if (isRouteNotFound) {
      navigateToUrl(pathname403);

      return true;
    }

    const [selectedMenuElement, selectedMenuItem] = getSelectedMenuItem(
      fullMenuConfiguration
    );

    const isMenuItemNotAllowed =
      selectedMenuItem &&
      !getIsMenuComponentAllowed(selectedMenuItem, allAccessConfiguration);

    const isMenuElementNotAllowed =
      selectedMenuElement &&
      !isModuleWithoutMainMenuEntry() &&
      !getIsMenuComponentAllowed(selectedMenuElement, allAccessConfiguration);

    if (isMenuItemNotAllowed || isMenuElementNotAllowed) {
      navigateToUrl(pathname403);

      return true;
    }

    return false;
  };

  const updateMenuItems = (
    allAccessConfiguration: AllAccessConfiguration
  ): MenuElement[] => {
    if (isErrorPage() && menuItems.length) {
      return menuItems;
    }

    let menuElements: MenuElement[];

    const isSystemUser = LoginService.isSuperUser();

    if (
      isConfigurationModule() ||
      isConfigurationv2TenantContextModule() ||
      isNotificationSubscriptions() ||
      isNotificationsTenantContextDashboard()
    ) {
      menuElements = getAllowedConfigurationMenu(allAccessConfiguration);
    } else if (isChangelogModule()) {
      menuElements = getAllowedChangelogMenu(allAccessConfiguration);
    } else if (!isSystemUser && isWorkflowsModule()) {
      menuElements = getAllowedWorkflowsMenu(allAccessConfiguration);
    } else if (!isSystemUser && isCentralReservationOfficeModule()) {
      menuElements = getAllowedCentralReservationOfficeMenu(
        allAccessConfiguration
      );
    } else {
      menuElements = getAllowedMainApplicationMenu(allAccessConfiguration);
    }
    setMenuItems(menuElements);
    recalculateItemsCount();

    return menuElements;
  };

  const unloadEmberApplication = async (): Promise<void> => {
    const scope = getAppScopeFromPathname();
    if (
      !!usedApp &&
      emberPathNameAppNameMap[scope] !== usedApp &&
      isEmberAppByAppName(usedApp)
    ) {
      await unloadApplication(usedApp ?? '');
    }
    usedApp = emberPathNameAppNameMap[scope];
  };

  const updateRoute = async (event?: Event): Promise<void> => {
    const isPopstateEvent = event && event.type === 'popstate';
    if (isPopstateEvent) {
      const newPathname = location.pathname;
      if (newPathname === locationRef.current) {
        return;
      }
      locationRef.current = newPathname;
    }

    await unloadEmberApplication();
    // Only modules which are editable in global region care for readOnly mode.
    // Each time we enter into such module, if we are not in global region, we should be in readOnly mode.
    // That's why we active readOnly mode for modules other then the ones editable in global region.
    if (isReadOnlyRequired()) {
      const newValue = getReadOnlyByUser() ?? true;
      setReadOnlyMode(newValue);
    }

    if (isBusinessContextDataGoingToChange()) {
      return;
    }

    const allAccessConfiguration = getAllAccessConfiguration();

    const menuElements: MenuElement[] = updateMenuItems(allAccessConfiguration);
    const [element, item] = getSelectedMenuItem(menuElements);

    const isRedirected = redirectWithoutMenuItemAccess(
      allAccessConfiguration,
      element
    );

    if (isRedirected) {
      return;
    }

    if (element) {
      handleSelectRoute(element?.id ?? '', item?.link);
    }

    const id = element?.id ?? selectedItem;

    if (id) {
      await adjustScrollToCurrentMenuItem(
        id,
        menuElements,
        onScrollDown,
        onScrollUp
      );
      forcedRecalculateUpdate(new Date());
    }
  };

  const handleSelectRoute = (
    menuElementId: string,
    link: string | undefined
  ): void => {
    setSelectedItem(menuElementId);
    setSelectedRoute(link);
  };

  const onButtonHandleSelectRoute = (
    menuElementId: string,
    link: string | undefined
  ): void => {
    setSelectedItem(menuElementId);
    setSelectedRoute(link);
  };

  const onScrollUp = (
    _event?: SyntheticEvent,
    menuElements?: MenuElement[]
  ): void => {
    setScrollDownDisabled(false);
    const elements = menuElements || menuItems;
    const count = getAvailableMenuItemsCount(
      isPathnameWithoutProperty(),
      false,
      (endIndex ?? 0) <= 0 ? elements.length : (endIndex ?? 0) + 1
    );
    const newEndIndex = (endIndex ?? 0) - count;
    const countStart = getAvailableMenuItemsCount(
      isPathnameWithoutProperty(),
      false,
      newEndIndex + 1
    );

    let newStartIndex = newEndIndex - countStart;
    if (newEndIndex > 0) {
      newStartIndex += 1;
    }
    if (newStartIndex <= 0) {
      newStartIndex = 0;
      setScrollUpDisabled(true);
    } else {
      setScrollUpDisabled(false);
    }

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
    const hiddenIndex = getAvailableMenuItemsCount(isPathnameWithoutProperty());
    forceUpdate(hiddenIndex + 1);

    scrollToSelectedMenuItem(elements[newStartIndex].id);
  };

  const onScrollDown = (
    _event?: SyntheticEvent,
    menuElements?: MenuElement[]
  ): void => {
    setScrollUpDisabled(false);
    const elements = menuElements || menuItems;
    const length = elements.length;
    const onScrollDownIndex = getNewStartEndIndexOnScrollDown();
    const { newStartIndex } = onScrollDownIndex;
    let { newEndIndex } = onScrollDownIndex;

    if (newEndIndex >= length - 1) {
      newEndIndex = length - 1;
      setScrollDownDisabled(true);
    } else {
      setScrollDownDisabled(false);
    }
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
    forceUpdate(newEndIndex + 1);
    scrollToSelectedMenuItem(elements[newStartIndex].id);
  };

  const areArrowsVisible = useMemo(() => {
    return (
      menuItems.length > getAvailableMenuItemsCount(isPathnameWithoutProperty())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedCurrentIndex, forcedRecalculate, menuItems.length]);

  const menuBarClasses = useMemo(() => {
    const classes = [
      'menu-bar',
      isTrainingBarVisible && useNew && 'menu-bar-training',
      isPathnameWithoutProperty() ? 'without-property' : '',
      isChangelogModuleForSuperAdmin() ? 'with-customer-select' : '',
      isConfigurationModuleForSuperAdmin() ||
      isConfigurationv2ModuleForSuperAdmin()
        ? 'without-bell'
        : '',
    ];

    return classes.filter(Boolean).join(' ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    menuItems.length,
    forcedRecalculate,
    window.location.pathname,
    isTrainingBarVisible,
  ]);

  const content: FC<void> = () => {
    if (isError403IpWhitlelistPage()) {
      return <>{LoginService.isLoggedIn() && <User />} </>;
    }

    return (
      <>
        {menuItems && menuItems.length ? (
          <ac-flex
            grow={true}
            class="items-container"
            id="menu-items-container"
            direction={FlexDirection.column}
          >
            {menuItems.map((menuItem, index) => (
              <Item
                key={menuItem.id}
                isSelected={menuItem.id === selectedItem}
                selectedRoute={selectedRoute}
                menuElement={menuItem}
                setSelectedRoute={onButtonHandleSelectRoute}
                class={index === forcedCurrentIndex ? 'menu-item-hidden' : ''}
              />
            ))}
          </ac-flex>
        ) : (
          <></>
        )}
        {areArrowsVisible && !isNotificationsTenantContextDashboard() && (
          <Arrows
            onScrollUp={onScrollUp}
            onScrollDown={onScrollDown}
            scrollDownDisabled={scrollDownDisabled}
            scrollUpDisabled={scrollUpDisabled}
          />
        )}
        {!useNew &&
          isPropertyContextVisible() &&
          !isNotificationsTenantContextDashboard() && <PropertyContext />}
        {!useNew &&
          isCroContextVisible() &&
          !isNotificationsTenantContextDashboard() && <CroContext />}
        {!useNew && isCashierVisible() && <Cashier />}
        {!useNew && isWorkstationVisible && <WorkstationMenu />}
        {!useNew && isBellVisible() && <NotificationBellContainer />}
        {LoginService.isLoggedIn() && userService.getCurrentUserDetails() && (
          <User />
        )}
      </>
    );
  };

  return (
    <div>
      <div className="container-main-menu">
        <Logo />
        <ac-box class="menu-bar-wrapper">
          <ac-flex
            alignItems={AlignItems.center}
            dynamicClass={menuBarClasses}
            justifyContent={
              isError403IpWhitlelistPage()
                ? JustifyContent.flexEnd
                : JustifyContent.normal
            }
            direction={
              isError403IpWhitlelistPage()
                ? FlexDirection.row
                : FlexDirection.column
            }
          >
            {content()}
          </ac-flex>
        </ac-box>
      </div>
      {useNew && <HorizontalBar />}
      {isTrainingBarVisible && <TrainingBar />}
    </div>
  );
};
