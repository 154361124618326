import { useEffect, useState } from 'react';
import { handleTokenExpiration } from 'frontend-container/components/TokenExpirationGuard/handleTokenExpiration';

import { LoginService } from '@ac/library-utils/dist/services';

import './TokenExpirationGuard.scss';

type Props = {
  enforceAuthorizationTimeoutInMs?: number;
};

export const TokenExpirationGuard = ({
  enforceAuthorizationTimeoutInMs = 5_000,
}: Props): JSX.Element | null => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() =>
    LoginService.isLoggedIn()
  );

  useEffect(() => {
    const cleanUp = handleTokenExpiration(
      setIsLoggedIn,
      enforceAuthorizationTimeoutInMs
    );

    return () => {
      cleanUp();
    };
  }, [setIsLoggedIn, enforceAuthorizationTimeoutInMs]);

  useEffect(() => {
    if (isLoggedIn) {
      return;
    }

    const stopEventPropagation = (event: Event): void => {
      event.stopPropagation();
    };

    window.addEventListener('keydown', stopEventPropagation, true);

    return () => {
      window.removeEventListener('keydown', stopEventPropagation, true);
    };
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return null;
  }

  return <ac-loader-covering dynamicClass="token-expiration-guard" />;
};
