import { initializeBaseApiErrorLogger } from 'frontend-container/utils/api/initialization';
import { initLoginServiceLogger } from 'frontend-container/utils/loginService/loginServiceInitializers';
import { initializeMetricsOnce } from 'frontend-container/utils/metrics/initialize';

import { LoggerService } from '@ac/library-api';

export const initServicesWithLogger = (logger: LoggerService): void => {
  initializeBaseApiErrorLogger(logger);
  initLoginServiceLogger(logger);
  initializeMetricsOnce(logger);
};
