import { useEffect } from 'react';
import { getIsNonProduction } from 'frontend-container/components/Menu/service';

import { acConfig } from '@ac/library-utils/dist/declarations';

const TRAINING_BAR_ROUTES = [acConfig.newFrontendUrls.changelog];

const isTrainingRoute = (route: string): boolean => {
  return !!TRAINING_BAR_ROUTES.find((routeToCheck) =>
    route.startsWith(routeToCheck)
  );
};

const useTrainingColor = (isTraining: boolean): void => {
  useEffect(() => {
    if (isTraining) {
      document.documentElement.style.setProperty(
        '--color-breadcrumbs-in-training',
        'var(--color-pink-in-training)'
      );
    } else {
      document.documentElement.style.removeProperty(
        '--color-breadcrumbs-in-training'
      );
    }
  }, [isTraining]);
};

const useTrainingBarHeight = (isTrainingBarVisible: boolean): void => {
  useEffect(() => {
    if (isTrainingBarVisible) {
      document.documentElement.style.setProperty(
        '--menu-height',
        'calc(2 * var(--menu-height-default))'
      );
    } else {
      document.documentElement.style.setProperty(
        '--menu-height',
        'var(--menu-height-default)'
      );
    }
  }, [isTrainingBarVisible]);
};

export const useTrainingFeature = (route: string): boolean => {
  const isTraining = getIsNonProduction();
  const isTrainingBarVisible = isTraining && isTrainingRoute(route);
  useTrainingColor(isTraining);
  useTrainingBarHeight(isTrainingBarVisible);

  return isTrainingBarVisible;
};
