import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { userPreferencesService } from 'frontend-container/components/Menu/components/User/UserPreferences/service';
import {
  PrestepDictionaries,
  PrestepUserPreferencesForm,
} from 'frontend-container/components/Prestep/form/PrestepForm';
import { getUserUnitsDetails } from 'frontend-container/components/Prestep/getUserUnitsDetails';
import { UserDetails } from 'frontend-container/components/Prestep/UserDetails/UserDetails';

import { Nullable, UserPreferencesDetails } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  createUseField,
  mapFieldInputPropsToSelectProps,
  useForm,
} from '@ac/react-infrastructure';
import {
  AlignItems,
  ButtonPattern,
  JustifyContent,
  TargetValueObject,
  TransparentContainerTheme,
} from '@ac/web-components';

const useField = createUseField<PrestepUserPreferencesForm>();

type DictionaryUnitNameCode = {
  name?: Nullable<string>;
  code?: Nullable<string>;
};

interface Props {
  dictionaries: PrestepDictionaries;
  onConfirm: (userPreferences?: UserPreferencesDetails) => void;
}

export const PrestepFormFields: FC<Props> = ({ dictionaries, onConfirm }) => {
  const workspaceField = useField('defaultWorkspace');
  const keepAsDefaultField = useField('keepAsDefault');
  const croUnitField = useField('defaultCentralReservationOfficeId');
  const propertyField = useField('defaultPropertyId');
  const form = useForm<PrestepUserPreferencesForm>();
  const { t } = useTranslation();

  const combineDictionaryEntriesNameWithCode = <
    T extends DictionaryUnitNameCode[]
  >(
    units: T
  ): T => {
    const mappedUnits = units.map((unit) => ({
      ...unit,
      name: [unit.code, unit.name].filter(isDefined).join(' - '),
    }));

    return mappedUnits as T;
  };

  const unitsOptionsArray = combineDictionaryEntriesNameWithCode(
    dictionaries.units
  );

  const croUnitsOptionsArray = combineDictionaryEntriesNameWithCode(
    dictionaries.croUnits
  );

  const userDictionariesDetails = getUserUnitsDetails(
    dictionaries.units,
    dictionaries.croUnits
  );

  const isRadioButtonDisplayed =
    !userDictionariesDetails.isUserWithoutCroUnits &&
    !userDictionariesDetails.isUserWithoutProperties;

  const translationToLowerCase = (
    translation: string,
    isLowerCase?: boolean
  ): string => (isLowerCase ? translation.toLowerCase() : translation);

  const getTranslation = (
    translationKey: 'DESCRIPTION' | 'HEADER',
    isLowerCase?: boolean
  ): string => {
    const translationPath = `LOGIN_PRESTEP.${translationKey}`;

    if (isRadioButtonDisplayed) {
      return t(translationPath, {
        workspace: translationToLowerCase(
          t('LOGIN_PRESTEP.WORKSPACE'),
          isLowerCase
        ),
      });
    }

    if (
      userDictionariesDetails.isMultiCroUser &&
      userDictionariesDetails.isUserWithoutProperties
    ) {
      return t(translationPath, {
        workspace: translationToLowerCase(
          t('LOGIN_PRESTEP.CRO_SHORT'),
          isLowerCase
        ),
      });
    }

    return t(translationPath, {
      workspace: translationToLowerCase(
        t('LOGIN_PRESTEP.PROPERTY'),
        isLowerCase
      ),
    });
  };

  const handleConfirm = async (): Promise<void> => {
    const currentUserPreferences =
      userService.getFullCurrentUser().userPreferences?.preferences;

    const {
      keepAsDefault,
      defaultCentralReservationOfficeId,
      defaultPropertyId,
      defaultWorkspace,
    } = form.getState().values;

    const userPreferences: UserPreferencesDetails = {
      ...currentUserPreferences,
      defaultCentralReservationOfficeId,
      defaultPropertyId,
    };

    if (isRadioButtonDisplayed) {
      userPreferences.defaultWorkspace = defaultWorkspace;
    }

    if (keepAsDefault) {
      await userPreferencesService.saveUserPreferences(userPreferences);
    }

    if (defaultCentralReservationOfficeId) {
      userPreferencesService.setDefaultCRO(userPreferences);
      if (!isRadioButtonDisplayed) {
        userPreferences.defaultWorkspace = ContextType.CRO;
      }
    }

    if (defaultPropertyId) {
      userPreferencesService.setDefaultProperty(userPreferences);
      if (!isRadioButtonDisplayed) {
        userPreferences.defaultWorkspace = ContextType.PROPERTY;
      }
    }

    onConfirm(userPreferences);
  };

  const isCROSelectDisplayed = isRadioButtonDisplayed
    ? workspaceField.input.value === ContextType.CRO
    : userDictionariesDetails.isMultiCroUser;

  const isPropertySelectDisplayed = isRadioButtonDisplayed
    ? workspaceField.input.value === ContextType.PROPERTY
    : userDictionariesDetails.isMultiPropertyUser;

  const isPropertyIdValid =
    propertyField.input.value || userDictionariesDetails.isSinglePropertyUser;
  const isCroIdValid =
    croUnitField.input.value || userDictionariesDetails.isSingleCroUser;

  const isConfirmButtonDisabled =
    (isPropertySelectDisplayed && !isPropertyIdValid) ||
    (isCROSelectDisplayed && !isCroIdValid);

  const propertyText = t('LOGIN_PRESTEP.PROPERTY');
  const croText = t('LOGIN_PRESTEP.CRO');

  return (
    <ac-flex
      class="prestep-view"
      justifyContent={JustifyContent.center}
      alignItems={AlignItems.center}
    >
      <ac-modal
        modalTitle={getTranslation('HEADER')}
        customButtons={[
          {
            content: { text: t('LOGIN_PRESTEP.BUTTONS.LOG_OUT') },
            onClick: () => userService.logout(false, true),
            pattern: ButtonPattern.tertiary,
          },
        ]}
        confirmButton={{
          content: { text: t('LOGIN_PRESTEP.BUTTONS.CONFIRM') },
          onClick: handleConfirm,
          disabled: isConfirmButtonDisabled,
        }}
        loading={!isDefined(dictionaries)}
        containerTheme={TransparentContainerTheme.transparent}
      >
        <span>
          <ac-flex>
            <ac-box sizeSm={10}>
              <ac-box
                class={isRadioButtonDisplayed ? 'ac-spacing-bottom-md' : ''}
              >
                {getTranslation('DESCRIPTION', true)}
              </ac-box>
              <ac-box>
                {isRadioButtonDisplayed && (
                  <>
                    <ac-radio-button
                      label={croText}
                      class="ac-spacing-right-md"
                      checked={workspaceField.input.value === ContextType.CRO}
                      onChangeCallback={(): void =>
                        form.change('defaultWorkspace', ContextType.CRO)
                      }
                    />
                    <ac-radio-button
                      label={propertyText}
                      checked={
                        workspaceField.input.value === ContextType.PROPERTY
                      }
                      onChangeCallback={(): void =>
                        form.change('defaultWorkspace', ContextType.PROPERTY)
                      }
                    />
                  </>
                )}
              </ac-box>

              {isCROSelectDisplayed && (
                <ac-box class="ac-padding-right-lg ac-padding-top-lg">
                  <ac-select
                    {...mapFieldInputPropsToSelectProps(croUnitField.input)}
                    value={
                      userDictionariesDetails.isSingleCroUser
                        ? croUnitsOptionsArray[0].id
                        : croUnitField.input.value
                    }
                    label={croText}
                    disabled={userDictionariesDetails.isSingleCroUser}
                    optionsArray={croUnitsOptionsArray ?? []}
                    optionValueField="id"
                    optionNameField="name"
                    targetValue={TargetValueObject.modal}
                    onClearCallback={(): void => {
                      form.change(
                        'defaultCentralReservationOfficeId',
                        undefined
                      );
                    }}
                    allowClear
                  />
                </ac-box>
              )}

              {isPropertySelectDisplayed && (
                <ac-box class="ac-padding-right-lg ac-padding-top-lg">
                  <ac-select
                    {...mapFieldInputPropsToSelectProps(propertyField.input)}
                    value={
                      userDictionariesDetails.isSinglePropertyUser
                        ? unitsOptionsArray[0].unitId
                        : propertyField.input.value
                    }
                    label={propertyText}
                    disabled={userDictionariesDetails.isSinglePropertyUser}
                    optionsArray={unitsOptionsArray ?? []}
                    optionValueField="unitId"
                    optionNameField="name"
                    targetValue={TargetValueObject.modal}
                    onClearCallback={(): void => {
                      form.change('defaultPropertyId', undefined);
                    }}
                    allowClear
                  />
                </ac-box>
              )}

              <ac-box class="ac-padding-top-md">
                <ac-checkbox
                  {...keepAsDefaultField.input}
                  label={t('LOGIN_PRESTEP.KEEP_AS_DEFAULT')}
                  class="ac-spacing-bottom-sm"
                />
              </ac-box>
            </ac-box>

            <ac-box sizeSm="auto" grow>
              <UserDetails />
            </ac-box>
          </ac-flex>
          <ac-box>{t('LOGIN_PRESTEP.KEEP_AS_DEFAULT_DETAILS')}</ac-box>
        </span>
      </ac-modal>
    </ac-flex>
  );
};
