import { Context } from 'frontend-container/components/Menu/components/Context';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { getCroContextById } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyContextById } from 'frontend-container/components/Menu/components/PropertyContext/service';
import {
  NavigateTo,
  NavigateToCro,
  NavigateToModule,
  NavigateToOptions,
  NavigateToProperty,
  Router,
} from 'frontend-container/publicApi/types';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import {
  contextTypeRegionChangeQueryParamMapper,
  getOriginForNewRegion,
} from 'frontend-container/utils/region/getUrlForNewRegion';

import { acConfig } from '@ac/library-utils/dist/declarations';
import {
  SEPFrontendUrlKey,
  SEPInternalFrontendUrlKeyType,
} from '@ac/library-utils/dist/declarations/config';
import { isEmpty, trim } from '@ac/library-utils/dist/utils';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

const createRoute = (
  route: string,
  { subroute, queryParams, searchParams }: NavigateToOptions = {}
): string => {
  /* TODO: It will be removed as a part of the story: https://jira.shijidev.com/browse/ACFC-2639 */
  if (!isEmpty(queryParams)) {
    return trim`${route}${subroute}?${new URLSearchParams(queryParams)}`;
  }

  if (!isEmpty(searchParams?.toString())) {
    return trim`${route}${subroute}?${searchParams}`;
  }

  return trim`${route}${subroute}`;
};

const createRegionalRoute = (
  route: string,
  context: Context,
  { subroute, queryParams, searchParams }: NavigateToOptions = {}
): string => {
  const region = findRegionData(context.regionCode ?? '');
  const origin = getOriginForNewRegion(
    region?.code ?? '',
    region?.domain ?? ''
  );
  const path = createRoute(route, { subroute, queryParams, searchParams });

  return trim`${origin}${path}`;
};

/* TODO: It will be removed as a part of the story: https://jira.shijidev.com/browse/ACFC-2639 */
const getContextQueryParam = (context: Context): Record<string, string> => ({
  [contextTypeRegionChangeQueryParamMapper(context)]: context.id,
});

const getContextSearchParamTuple = (context: Context): [string, string] => [
  contextTypeRegionChangeQueryParamMapper(context),
  context.id,
];

const getModulePath = (module: SEPInternalFrontendUrlKeyType): string =>
  acConfig.newFrontendUrls[module];

const navigate = (route: string, inNewTab: boolean = false): void => {
  if (inNewTab) {
    window.open(route, '_blank');
  } else {
    window.location.replace(route);
  }
};

const navigateTo: NavigateTo = (
  relativePath,
  { queryParams, searchParams, newTab } = {}
) => {
  const route = createRoute(relativePath, { queryParams, searchParams });
  navigate(route, newTab);
};

const navigateToModule: NavigateToModule = (
  module,
  { subroute, queryParams, searchParams, newTab } = {}
) => {
  const route = createRoute(getModulePath(module), {
    subroute,
    queryParams,
    searchParams,
  });
  navigate(route, newTab);
};

const navigateToProperty: NavigateToProperty = async (
  propertyId,
  module,
  { subroute, queryParams, searchParams, newTab } = {}
) => {
  const propertyContext = getPropertyContextById(propertyId);

  if (!propertyContext) {
    throw new Error(
      `Failed to navigate to property. Property context "${propertyId}" not found`
    );
  }

  setSessionContext(propertyContext, newTab);

  /* TODO: It will be removed as a part of the story: https://jira.shijidev.com/browse/ACFC-2639 */
  const queryParamsWithContext = {
    ...queryParams,
    ...getContextQueryParam(propertyContext),
  };

  const searchParamsWithContext = new URLSearchParams(searchParams);
  searchParamsWithContext.append(
    ...getContextSearchParamTuple(propertyContext)
  );

  const shouldQueryParamsBePassed = !!queryParams && !searchParams;
  const shouldSearchParamsBePassed = !shouldQueryParamsBePassed;

  if (propertyContext.regionCode === getCurrentRegionCode()) {
    return navigateToModule(module, {
      subroute,
      newTab,
      /* TODO: It will be removed as a part of the story: https://jira.shijidev.com/browse/ACFC-2639 */
      ...(shouldQueryParamsBePassed && {
        queryParams: newTab ? queryParamsWithContext : queryParams,
      }),
      ...(shouldSearchParamsBePassed && {
        searchParams: newTab ? searchParamsWithContext : searchParams,
      }),
    });
  }

  const regionalRoute = createRegionalRoute(
    getModulePath(module),
    propertyContext,
    {
      subroute,
      /* TODO: It will be removed as a part of the story: https://jira.shijidev.com/browse/ACFC-2639 */
      ...(shouldQueryParamsBePassed && { queryParams: queryParamsWithContext }),
      ...(shouldSearchParamsBePassed && {
        searchParams: searchParamsWithContext,
      }),
    }
  );

  navigate(regionalRoute, newTab);
};

const navigateToCro: NavigateToCro = async (
  croId,
  { subroute, queryParams, searchParams, newTab } = {}
) => {
  const croContext = getCroContextById(croId);

  if (!croContext) {
    throw new Error(
      `Failed to navigate to CRO. CRO context "${croId}" not found`
    );
  }

  setSessionContext(croContext, newTab);

  /* TODO: It will be removed as a part of the story: https://jira.shijidev.com/browse/ACFC-2639 */
  const queryParamsWithContext = {
    ...queryParams,
    ...getContextQueryParam(croContext),
  };

  const searchParamsWithContext = new URLSearchParams(searchParams);
  searchParamsWithContext.append(...getContextSearchParamTuple(croContext));

  const shouldQueryParamsBePassed = !!queryParams && !searchParams;
  const shouldSearchParamsBePassed = !shouldQueryParamsBePassed;

  if (croContext.regionCode === getCurrentRegionCode()) {
    return navigateToModule(SEPFrontendUrlKey.CENTRAL_RESERVATION_OFFICE, {
      subroute,
      newTab,
      /* TODO: It will be removed as a part of the story: https://jira.shijidev.com/browse/ACFC-2639 */
      ...(shouldQueryParamsBePassed && {
        queryParams: newTab ? queryParamsWithContext : queryParams,
      }),
      ...(shouldSearchParamsBePassed && {
        searchParams: newTab ? searchParamsWithContext : searchParams,
      }),
    });
  }

  const regionalRoute = createRegionalRoute(
    getModulePath(SEPFrontendUrlKey.CENTRAL_RESERVATION_OFFICE),
    croContext,
    {
      subroute,
      /* TODO: It will be removed as a part of the story: https://jira.shijidev.com/browse/ACFC-2639 */
      ...(shouldQueryParamsBePassed && { queryParams: queryParamsWithContext }),
      ...(shouldSearchParamsBePassed && {
        searchParams: searchParamsWithContext,
      }),
    }
  );

  navigate(regionalRoute, newTab);
};

export const createRouter = (): Router => ({
  navigateTo,
  navigateToModule,
  navigateToProperty,
  navigateToCro,
});
