import { UserEnvironmentData } from 'frontend-container/components/Menu/components/Logo/MaintenanceModal/types';

import { formatDate } from '../../../../../utils/dateUtils';

const globalUserAgent = window.navigator.userAgent;

const getGenericVersion = (name: string, userAgent: string): string => {
  const matchStringPattern = `.*${name}([0-9_|.]*).*`;
  const newMatchRegex = new RegExp(matchStringPattern, 'i');
  const version = userAgent.match(newMatchRegex);

  return version ? version[1] : '';
};

const getBrowserDetails = (userAgent: string): string => {
  const matchedBrowserDetails =
    userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];

  const matchedBrowserName = matchedBrowserDetails[1];
  const formattedBrowserName =
    matchedBrowserName === 'MSIE' || matchedBrowserName === 'trident'
      ? `${matchedBrowserName} `
      : `${matchedBrowserName}/`;

  if (matchedBrowserName === 'Chrome') {
    const matchedDetails = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (matchedDetails !== null) {
      return matchedDetails.slice(1).join(' ').replace('OPR', 'Opera');
    }
  }
  const version = getGenericVersion(formattedBrowserName, userAgent);
  const matchedBrowserversion = matchedBrowserDetails[2];

  const browserDetails =
    matchedBrowserName && version
      ? [matchedBrowserName, version]
      : matchedBrowserversion
      ? [matchedBrowserName, matchedBrowserversion]
      : [navigator.appName, navigator.appVersion, '-?'];
  const matchedVersion = userAgent.match(/version\/(\d+)/i);

  if (matchedVersion !== null) {
    browserDetails.splice(1, 1, matchedVersion[1]);
  }

  return browserDetails.join(' ');
};

export const getUserEnvironmentDetails = (
  userAgent: string = globalUserAgent
): UserEnvironmentData[] => {
  const getWindowDimensions = (): string => {
    const hasWindow = typeof window !== 'undefined';
    const width = hasWindow ? window.innerWidth : '';
    const height = hasWindow ? window.innerHeight : '';

    return width.toString().concat('*', height.toString());
  };

  const browserCookieStatus = (): string => {
    return navigator.cookieEnabled ? 'Enabled' : 'Disabled';
  };

  const getPlatformDetails = (): string => {
    return getPlatformFromUserAgent();
  };

  const getPlatformFromUserAgent = (): string => {
    const platform = userAgent.match(/(Windows NT|CrOS|Mac OS|Linux)/) || [];
    const osName = platform.length ? platform[0] : 'unknown';
    const version = osName !== 'unknown' ? getPlatformVersion(platform[0]) : '';

    return osName.concat(' ', version);
  };

  const getPlatformVersion = (osName: string): string => {
    switch (osName) {
      case 'Windows NT':
        return getGenericVersion(`${osName} `, userAgent);
      case 'CrOS':
      case 'Linux':
        return getGenericVersion(`${osName} x`, userAgent);
      case 'Mac OS':
        return iOSVersion(true);
      default:
        return '';
    }
  };

  const isMobile = (): boolean => {
    return userAgent.toLowerCase().includes('mobi');
  };

  const getDeviceName = (): string => {
    const deviceDetails =
      userAgent.match(
        /Android|BlackBerry|Bluebird|Chrome OS|Datalogic|Honeywell|iPhone|iPad|iPod|macOS|Windows Phone|Zebra/
      ) || [];
    const device = deviceDetails.length ? deviceDetails[0] : 'unknown';

    return device.concat(' ', getDeviceVersion(device.toLowerCase()));
  };

  const getDeviceVersion = (device: string): string => {
    switch (device) {
      case 'android':
      case 'blackberry':
      case 'windows':
        return getGenericVersion(`${device} `, userAgent);
      case 'ipad':
      case 'ipod':
      case 'iphone':
        return iOSVersion();
      default:
        return '';
    }
  };

  const iOSVersion = (isMacOS: boolean = false): string => {
    const deviceVersion = !isMacOS
      ? userAgent.match(/OS\s([0-9_]*)/i)
      : userAgent.match(/OS X\s([0-9_]*)/i);

    return deviceVersion ? deviceVersion[1].replace(/_/g, '.') : '';
  };

  const getCurrentDateTime = (): string => {
    const currentDateTime = new Date(Date.now());

    return formatDate(currentDateTime, 'DD-MM-YYYY h:m:s a');
  };

  const webSocketSupport = (): string => {
    return window.WebSocket ? 'Available' : 'Not available';
  };

  const mobile = isMobile();

  return [
    { name: 'BROWSER_DETAILS', details: getBrowserDetails(userAgent) },
    { name: 'SCREEN_SIZE', details: getWindowDimensions() },
    { name: 'COOKIE_ENABLED', details: browserCookieStatus() },
    { name: 'GENERATED_TIME', details: getCurrentDateTime() },
    { name: 'WEB_SOCKET_SUPPORT', details: webSocketSupport() },
    ...(!mobile ? [{ name: 'OS_DETAILS', details: getPlatformDetails() }] : []),
    ...(mobile ? [{ name: 'DEVICE_NAME', details: getDeviceName() }] : []),
  ];
};

export const isSafariBrowser = (
  userAgent: string = globalUserAgent
): boolean => {
  const browserDetails = getBrowserDetails(userAgent);

  return browserDetails.toLowerCase().includes('safari');
};
