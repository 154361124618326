import { useCallback } from 'react';
import { navigateToUrl } from 'single-spa';

import { BusinessContextUnitIdentifier } from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';
import {
  ButtonPattern,
  ButtonTheme,
  DividerDirection,
  IconName,
  Size,
  TextColor,
} from '@ac/web-components';

interface Props {
  title: string;
  route: string;
  icon: IconName;
  targetUnit: BusinessContextUnitIdentifier;
}

export const SwitchToContextType = ({
  title,
  route,
  icon,
  targetUnit,
}: Props): JSX.Element => {
  const switchToContextAction = useCallback(() => {
    if (!targetUnit.centralReservationOfficeId && !targetUnit.propertyId) {
      throw new Error(
        'Only Property and/or CentralReservationOffice context switch is supported'
      );
    }

    navigateToUrl(route);
    if (targetUnit.centralReservationOfficeId) {
      SessionService.setCentralReservationOfficeId(
        targetUnit.centralReservationOfficeId
      );
    } else {
      SessionService.setPropertyId(targetUnit.propertyId);
    }
  }, [route, targetUnit]);

  return (
    <div className="container-menu-item">
      <ac-button
        pattern={ButtonPattern.tertiary}
        theme={ButtonTheme.light}
        onClick={switchToContextAction}
      >
        <ac-icon icon={icon} size={Size.lg} />
        <ac-text color={TextColor.white} class="ac-spacing-left-sm">
          {title}
        </ac-text>
      </ac-button>
      <ac-divider direction={DividerDirection.vertical} class="bg-gray2" />
    </div>
  );
};
