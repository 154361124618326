import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isAccountsReceivableModule } from 'frontend-container/components/Menu/utils/modules/accountsReceivable';
import { isActivityConfigurationModule } from 'frontend-container/components/Menu/utils/modules/activityConfiguration';
import { isActivityReservationsModule } from 'frontend-container/components/Menu/utils/modules/activityReservations';
import { isAvailabilityModule } from 'frontend-container/components/Menu/utils/modules/availability';
import { isCashieringModule } from 'frontend-container/components/Menu/utils/modules/cashiering';
import { isAuditChangelog } from 'frontend-container/components/Menu/utils/modules/changelog';
import { isConfigurationv2InPropertyContext } from 'frontend-container/components/Menu/utils/modules/configurationv2';
import { isHousekeepingModule } from 'frontend-container/components/Menu/utils/modules/housekeeping';
import { isIndividualReservationsModule } from 'frontend-container/components/Menu/utils/modules/individualReservations';
import { isIntegrationsModuleWithProperty } from 'frontend-container/components/Menu/utils/modules/integrations';
import { isPropertyDependentInventoryModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { isItineraryModule } from 'frontend-container/components/Menu/utils/modules/itinerary';
import { isMeetingsAndEventsModule } from 'frontend-container/components/Menu/utils/modules/meetingsAndEvents';
import { isNotificationsDashboard } from 'frontend-container/components/Menu/utils/modules/notifications';
import { isProfilesModule } from 'frontend-container/components/Menu/utils/modules/profiles';
import { isRateManagerModule } from 'frontend-container/components/Menu/utils/modules/rateManager';
import { isReportsModule } from 'frontend-container/components/Menu/utils/modules/reports';
import { isReservationsModule } from 'frontend-container/components/Menu/utils/modules/reservations';
import { isReservationsGroupsModule } from 'frontend-container/components/Menu/utils/modules/reservations-groups';
import { isResourceManagementModule } from 'frontend-container/components/Menu/utils/modules/resourceManagement';
import { isTaskManagementModule } from 'frontend-container/components/Menu/utils/modules/taskManagement';
import { isTravelAgentCommisionsModule } from 'frontend-container/components/Menu/utils/modules/travelAgentCommissions';
import { isWorkflowsModule } from 'frontend-container/components/Menu/utils/modules/workflows';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { isPropertyBusinessContextData } from '@ac/library-api';

const isPropertyBusinessContextOnErrorPage = (): boolean => {
  return (
    isErrorPage() && isPropertyBusinessContextData(getBusinessContextData())
  );
};

export const isPropertyContextVisible = (): boolean => {
  const pathUtilFunctions = [
    isAccountsReceivableModule,
    isMeetingsAndEventsModule,
    isTravelAgentCommisionsModule,
    isConfigurationv2InPropertyContext,
    isTaskManagementModule,
    isNotificationsDashboard,
    isReservationsModule,
    isIndividualReservationsModule,
    isReservationsGroupsModule,
    isHousekeepingModule,
    isRateManagerModule,
    isWorkflowsModule,
    isProfilesModule,
    isCashieringModule,
    isAuditChangelog,
    isIntegrationsModuleWithProperty,
    isActivityReservationsModule,
    isReportsModule,
    isActivityConfigurationModule,
    isAvailabilityModule,
    isPropertyDependentInventoryModule,
    isPropertyBusinessContextOnErrorPage,
    isResourceManagementModule,
    isItineraryModule,
  ];

  return pathUtilFunctions.some((func) => func());
};
