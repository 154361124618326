import { PureComponent } from 'react';
import { AutomaticLogout } from 'frontend-container/components/AutomaticLogout/AutomaticLogout';
import { ContextHelpSidePanel } from 'frontend-container/components/ContextHelp/ContextHelpSidePanel/ContextHelpSidePanel';
import { pathname404 } from 'frontend-container/components/Errors/Error404';
import { Branding } from 'frontend-container/components/Menu/components/Branding/Branding';
import { DocumentTitle } from 'frontend-container/components/Menu/components/DocumentTitle';
import { UnsavedChangesModal } from 'frontend-container/components/Menu/components/UnsavedChangesModal/UnsavedChangesModal';
import { Menu } from 'frontend-container/components/Menu/Menu';
import {
  shouldUseDarkTheme,
  shouldUseNewMenu,
} from 'frontend-container/components/Menu/service';
import { MultiRegionErrorHandler } from 'frontend-container/components/MultiRegionError/MultiRegionErrorHandler';
import { SessionDataSnapshotUpdater } from 'frontend-container/components/SessionData/SessionDataSnapshotUpdater';
import { Toast } from 'frontend-container/components/Toast';
import { TokenExpirationGuard } from 'frontend-container/components/TokenExpirationGuard/TokenExpirationGuard';
import { isLoginUrl, isMainUrl } from 'frontend-container/utils/location';
import { checkActivityFunctions, navigateToUrl } from 'single-spa';

import { withTranslationsReady } from '@ac/react-infrastructure';

import './App.scss';
import './AppDarkTheme.scss';
import './AppMediaPrint.scss';

class App extends PureComponent {
  public componentDidMount(): void {
    const appsThatShouldBeActive = checkActivityFunctions(window.location);
    if (!appsThatShouldBeActive.length && !isMainUrl() && !isLoginUrl()) {
      // todo: temporary console use, remove after confirmation of problem
      // eslint-disable-next-line no-console
      console.error(
        'WRONG LOCATION: ',
        window.location.pathname,
        appsThatShouldBeActive
      );
      navigateToUrl(pathname404);

      return;
    }

    const useNewMenu = shouldUseNewMenu();
    const useDarkTheme = shouldUseDarkTheme();

    if (useNewMenu) {
      document.body.classList.remove('old-menu');
      document.documentElement.style.setProperty('--menu-width', '48px');
    } else {
      document.documentElement.style.setProperty('--menu-width', '0px');
    }

    if (useDarkTheme) {
      document.body.classList.add('main-app-dark');
    }
  }

  public render(): JSX.Element | null {
    return (
      <>
        <Menu />
        <TokenExpirationGuard />
        <DocumentTitle />
        <Branding />
        <SessionDataSnapshotUpdater />
        <MultiRegionErrorHandler />
        <AutomaticLogout />
        <ContextHelpSidePanel />
        <UnsavedChangesModal />
        <Toast />
      </>
    );
  }
}

const AppWithTranslationsReady = withTranslationsReady(App);

export { AppWithTranslationsReady as App };
