export const pathnamesToHelpIdMap: Record<string, number> = {
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/tax-calculation-rule': 1002,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/tax-calculation-rule/:id': 1002,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/billing-instruction-preset': 1011,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/billing-instruction-preset/:id': 1011,
  '/configuration/customers/:id/details/user-notifications': 1029,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/commission-code': 1063,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/commission-code/:id/details': 1063,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/commission-code/:id/travel-agents': 1063,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/purchase-element': 1065,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/purchase-element/:id/details': 1065,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/purchase-element/:id/pricing': 1076,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/rate-plan': 1080,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/rate-plan/:id/details': 1080,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/rate-plan/:id/pricing': 1080,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/rate-plan/:id/daily': 1080,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/member-rate': 1072,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/member-rate/:id': 1072,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/rate-promotion': 1081,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/rate-promotion/:id': 1081,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/room-type': 1097,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/room-type/:id': 1097,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/:id/entity-manager/room-type/:id/details': 1097,
  '/configurationv2/customers/:id/users': 1203,
  '/configuration/customers/:id/details/user-roles': 1204,
  '/reports/batch-reports': 1207,
  '/reports/scheduler': 1208,
  '/housekeeping/room-history': 1209,
  '/housekeeping/attendant-assignments': 1210,
  '/housekeeping/maintenance-dashboard': 1211,
  '/cashiering/policy/deposits': 1213,
  '/cashiering/posting-journal': 1214,
  '/cashiering/cashier-closure/posting-journal': 1214,
  '/cashiering/number-list-view': 1215,
  '/cashiering/dashboard?foreignCurrencyExchange=true': 1216,
  '/profiles/prestep/cashiering': 1218,
  '/reservations/cashiering/accounts/new': 1218,
  '/reservations/cashiering/accounts/:id': 1244,
  '/profiles/prestep/individual': 1219,
  '/reservations/profiles/individual/new': 1219,
  '/profiles/prestep/company': 1220,
  '/reservations/profiles/company/new': 1220,
  '/profiles/prestep/travel-agent': 1221,
  '/reservations/profiles/travel-agent/new': 1221,
  '/reservations/profiles/individual/:id/reservations': 1223,
  '/reservations/profiles/individual/:id/analysis': 1225,
  '/reservations/profiles/company/:id/reservations': 1223,
  '/reservations/profiles/company/:id/analysis': 1225,
  '/reservations/profiles/travel-agent/:id/reservations': 1223,
  '/reservations/profiles/travel-agent/:id/analysis': 1225,
  '/profiles/duplicates/last-merged': 1226,
  '/profiles/duplicates/potential': 1227,
  '/profiles/prestep/reservation': 1228,
  '/reservations/reservations/new': 1228,
  '/reservations/availability/search/room-types': 1229,
  '/reservations/availability/search/rate-plans': 1229,
  '/reports/availability/detailed': 1230,
  '/rate-manager/grid': 1231,
  '/reservations/reservations/:id/check-in': 1232,
  '/reservations/reservations/:id': 1233,
  '/reservations/reservations-groups/:id/details': 1235,
  '/reservations/reservations-groups/new/details': 1378,
  '/reservations/reservations-groups/:id/room-grid': 1238,
  '/reservations/reservations-groups/new/room-grid': 1238,
  '/reservations/reservations-groups/:id/rooming-list': 1239,
  '/reservations/cashiering/billing/:id': 1240,
  '/reports/house-status-dashboard': 1241,
  '/reservations/room-planner': 1242,
  '/reservations/reservations/walk-in': 1243,
  '/accounts-receivable/:id/edit': 1245,
  '/accounts-receivable/:id/transactions': 1246,
  '/meetings-and-events/events-dashboard': 1248,
  '/meetings-and-events/booking/create': 1249,
  '/meetings-and-events/conference-diary': 1250,
  '/meetings-and-events/booking/:id/booking-details': 1253,
  '/meetings-and-events/booking/:id/create-event': 1254,
  '/meetings-and-events/booking/:id/events-overview': 1255,
  '/meetings-and-events/booking/:id/event-details/:id': 1256,
  '/reservations/reservations/inquiry': 1189,
  '/profiles/dashboard': 1190,
  '/profiles/dashboard/all': 1190,
  '/profiles/dashboard/individual': 1190,
  '/profiles/dashboard/company': 1190,
  '/profiles/dashboard/travel-agent': 1190,
  '/reservations/reservations': 1191,
  '/reservations/reservations-groups': 1192,
  '/reservations/frontdesk': 1193,
  '/cashiering/dashboard': 1194,
  '/housekeeping/dashboard': 1195,
  '/task-management/dashboard': 1196,
  '/reports/folders': 1197,
  '/cashiering/end-of-day': 1198,
  '/meetings-and-events/booking-dashboard': 1199,
  '/accounts-receivable/dashboard': 1200,
  '/changelog/technical-events-log': 1201,
  '/travel-agent-commissions/dashboard': 1202,
  '/integrations/dashboard': 1347,
  '/configuration/customers/:id/interfaces/new/:id/CRM': 1338,
  '/integrations/fiscal-interface/:id/CRM/new/adapter-configuration': 1338,
  '/integrations/fiscal-interface/:id/CRM/:id/adapter-configuration': 1338,
  '/integrations/fiscal-interface/:id/CRM/:id/control': 1338,
  '/integrations/:id/CRM/ReviewPro/:id/adapter-configuration': 1338,
  '/configuration/customers/:id/interfaces/edit/:id/CRM/Revinate/:id': 1338,
  '/configuration/customers/:id/interfaces/edit/:id/CRM/ReviewPro/:id': 1338,
  '/configuration/customers/:id/interfaces/adapter-dashboard/:id/OXI/:id/synchronizing': 1339,
  '/configuration/customers/:id/interfaces/adapter-dashboard/:id/Synxis/:id/synchronizing': 1339,
  '/configuration/customers/:id/interfaces/new/:id/CRS': 1340,
  '/integrations/fiscal-interface/:id/CRS/new/adapter-configuration': 1340,
  '/integrations/fiscal-interface/:id/CRS/:id/adapter-configuration': 1340,
  '/integrations/fiscal-interface/:id/CRS/:id/control': 1340,
  '/configuration/customers/:id/interfaces/edit/:id/CRS/OXI/:id': 1340,
  '/configuration/customers/:id/interfaces/edit/:id/CRS/Synxis/:id': 1340,
  '/configuration/customers/:id/interfaces/new/:id/Payment': 1342,
  '/integrations/fiscal-interface/:id/Payment/new/adapter-configuration': 1342,
  '/integrations/fiscal-interface/:id/Payment/:id/adapter-configuration': 1342,
  '/integrations/fiscal-interface/:id/Payment/:id/control': 1342,
  '/configuration/customers/:id/interfaces/edit/:id/Payment/ShijiSPAPayment/:id': 1342,
  '/configuration/customers/:id/interfaces/edit/:id/Payment/ShijiOnlinePayment/:id': 1342,
  '/configuration/customers/:id/interfaces/new/:id/PaymentRelated': 1343,
  '/integrations/fiscal-interface/:id/PaymentRelated/new/adapter-configuration': 1343,
  '/integrations/fiscal-interface/:id/PaymentRelated/:id/adapter-configuration': 1343,
  '/integrations/fiscal-interface/:id/PaymentRelated/:id/control': 1343,
  '/configuration/customers/:id/interfaces/edit/:id/PaymentRelated/AdyenAdapterConfiguration/:id': 1343,
  '/configuration/customers/:id/interfaces/edit/:id/PaymentRelated/DataVaultAdapterConfiguration/:id': 1343,
  '/integrations/:id//PaymentRelated/AdyenAdapterConfiguration/:id/adapter-configuration': 1343,
  '/configuration/customers/:id/interfaces/new/:id/Property': 1344,
  '/integrations/fiscal-interface/:id/Property/new/adapter-configuration': 1344,
  '/integrations/fiscal-interface/:id/Property/:id/adapter-configuration': 1344,
  '/integrations/fiscal-interface/:id/Property/:id/control': 1344,
  '/configuration/customers/:id/interfaces/edit/:id/Property/ShijiIFC/:id': 1344,
  '/integrations/:id/Property/ShijiIFC/:id/adapter-configuration': 1344,
  '/configuration/customers/:id/interfaces/adapter-dashboard/:id/Duetto/:id/synchronizing': 1345,
  '/configuration/customers/:id/interfaces/adapter-dashboard/:id/Ideas/:id/synchronizing': 1345,
  '/configuration/customers/:id/interfaces/new/:id/RevenueManagement': 1346,
  '/integrations/fiscal-interface/:id/RevenueManagement/new/adapter-configuration': 1346,
  '/integrations/fiscal-interface/:id/RevenueManagement/:id/adapter-configuration': 1346,
  '/integrations/fiscal-interface/:id/RevenueManagement/:id/control': 1346,
  '/configuration/customers/:id/interfaces/edit/:id/RevenueManagement/Duetto/:id': 1346,
  '/configuration/customers/:id/interfaces/edit/:id/RevenueManagement/Ideas/:id': 1346,
  '/configuration/customers/:id/webhook-management': 1349,
  '/configuration/customers/:id/webhook-management/new': 1348,
  '/configuration/customers/:id/interfaces/adapter-dashboard/:id/ShijiDistribution/:id/synchronizing': 1341,
  '/configuration/customers/:id/interfaces/new/:id/Distribution': 1350,
  '/integrations/fiscal-interface/:id/Distribution/new/adapter-configuration': 1350,
  '/integrations/fiscal-interface/:id/Distribution/:id/adapter-configuration': 1350,
  '/integrations/fiscal-interface/:id/Distribution/:id/control': 1350,
  '/configuration/customers/:id/interfaces/edit/:id/Distribution/ShijiDistribution/:id': 1350,
  '/integrations/:id/Distribution/ShijiDistribution/:id/adapter-configuration': 1350,
  '/integrations/:id/Distribution/ShijiDistribution/:id/control': 1350,
  '/reservations/cashiering/quick-posting': 1377,
  '/configuration/customers/:id/organization-structure': 1180,
  '/profiles/consent-and-activity/consent': 1181,
  '/profiles/consent-and-activity/activity': 1181,
  '/reservations/queue-rooms': 1380,
  '/meetings-and-events/function-space-rate-manager': 1381,
  '/accounts-receivable/transactions': 1382,
  '/accounts-receivable/create': 1383,
  '/configuration/customers/:id/user/new': 1407,
  '/configuration/customers/:id/user/:id/user-roles': 1408,
  '/rate-manager/hurdle-table': 1410,
  '/individual-reservations/guest-messages': 1411,
  '/configuration/customers/new': 1412,
  '/configuration/customers/:id/details/edit': 1412,
  '/configuration/customers/:id/details/zones': 1413,
  '/configuration/customers/:id/organization-structure/:id/subsidiaries/:id/zones': 1413,
  '/configuration/customers/:id/details/entity-manager': 1414,
  '/configuration/customers/:id/organization-structure/:id/subsidiaries/:id/entity-manager': 1414,
  '/configuration/customers/:id/organization-structure/:id/properties/:id/entity-manager': 1414,
  '/configuration/customers/:id/details/settings': 1415,
  '/configuration/customers/:id/organization-structure/:id/subsidiaries/:id/settings': 1415,
  '/configuration/customers/:id/organization-structure/:id/properties/:id/settings': 1415,
  '/configuration/customers/:id/interfaces': 1416,
  '/configuration/customers/:id/structure-owner/:id/configuration-zones/new/MultiSelectZone': 1450,
  '/configuration/customers/:id/organization-structure/:id/properties/:id/zones': 1451,
  '/configuration/customers/:id/organization-structure/:id/properties/:id/details': 1457,
  '/configuration/customers/:id/organization-structure/:id/properties/new': 1457,
  '/individual-reservations/wake-up-calls': 1464,
  '/changelog/system-integration-logs': 1466,
  '/integrations/:id/Distribution/SiteMinder/:id/control': 1508,
  '/integrations/:id/Distribution/SiteMinder/:id/setup': 1508,
  '/integrations/:id/Distribution/SiteMinder/:id/adapter-configuration': 1508,
  '/integrations/:id/CRS/Synxis/:id/control': 1509,
  '/integrations/:id/CRS/Synxis/:id/adapter-configuration': 1509,
  '/integrations/:id/CRS/GRS/:id/control/': 1510,
  '/integrations/:id/CRS/GRS/:id/adapter-configuration/': 1510,
  '/integrations/:id/CRS/OXI/:id/control': 1511,
  '/integrations/:id/CRS/OXI/:id/adapter-configuration': 1511,
  '/integrations/:id/DocumentScanning/GenericDocumentScanner/:id/adapter-configuration': 1512,
  '/integrations/:id/Fiscal/GermanEpsonTSE/:id/backup': 1515,
  '/integrations/:id/Fiscal/GermanEpsonTSE/:id/adapter-configuration': 1515,
  '/integrations/:id/Fiscal/Austria/:id/backup': 1516,
  '/integrations/:id/Fiscal/Austria/:id/adapter-configuration': 1516,
  '/integrations/:id/Fiscal/ShijiFiscalExchange/:id/backup': 1517,
  '/integrations/:id/Fiscal/ShijiFiscalExchange/:id/adapter-configuration': 1517,
  '/integrations/:id/LegalInterface/TurkiyePoliceReport/:id/adapter-configuration': 1518,
  '/integrations/:id/Payment/ShijiOnlinePayment/:id/adapter-configuration': 1520,
  '/integrations/:id/Payment/TokenizationService/:id/adapter-configuration': 1521,
  '/integrations/:id/PaymentRelated/DataVaultAdapterConfiguration/:id/adapter-configuration': 1522,
  '/integrations/:id/PaymentRelated/DataVaultAdapterConfiguration/:id/control': 1522,
  '/integrations/:id/RevenueManagement/Ideas/:id/adapter-configuration': 1524,
  '/integrations/:id/RevenueManagement/Ideas/:id/control': 1524,
  '/integrations/:id/FilesExport/DATEV/:id/control': 1525,
  '/integrations/:id/FilesExport/DATEV/:id/export-log': 1525,
  '/integrations/:id/FilesExport/DATEV/:id/adapter-configuration': 1525,
  '/integrations/:id/FilesExport/CTGSAP/:id/control': 1526,
  '/integrations/:id/FilesExport/CTGSAP/:id/export-log': 1526,
  '/integrations/:id/FilesExport/CTGSAP/:id/adapter-configuration': 1526,
  '/integrations/:id/FilesExport/GRS/:id/control': 1527,
  '/integrations/:id/FilesExport/GRS/:id/export-log': 1527,
  '/integrations/:id/FilesExport/GRS/:id/adapter-configuration': 1527,
  '/integrations/:id/FilesExport/Hesta/:id/control': 1528,
  '/integrations/:id/FilesExport/Hesta/:id/export-log': 1528,
  '/integrations/:id/FilesExport/Hesta/:id/adapter-configuration': 1528,
  '/integrations/:id/FilesExport/Onyx/:id/control': 1529,
  '/integrations/:id/FilesExport/Onyx/:id/export-log': 1529,
  '/integrations/:id/FilesExport/Onyx/:id/adapter-configuration': 1529,
  '/integrations/:id/FilesExport/SII/:id/control': 1529,
  '/integrations/:id/FilesExport/SII/:id/export-log': 1529,
  '/integrations/:id/FilesExport/SII/:id/adapter-configuration': 1529,
  '/activity-reservations/dashboard': 1531,
  '/activity-reservations/planner': 1532,
  '/profiles/prestep/activity-reservations': 1471,
  '/activity-reservations/reservations/new': 1471,
  '/activity-reservations/reservations/:id': 1471,
  '/activity-configuration/activity-manager': 1533,
  '/activity-configuration/activity-manager/add-activity': 1533,
  '/activity-configuration/rate-manager': 1534,
  '/activity-configuration/rate-manager/add-rate': 1534,
};
