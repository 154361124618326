import { businessContextDataCache } from 'frontend-container/shared/businessContext/cache';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
  SepModuleBusinessContextLoader,
  tryAdjustContextToUnitIdentifier,
} from '@ac/library-api';

export const loadBusinessContextData = async (
  unit: BusinessContextUnitIdentifier
): Promise<SepModuleBusinessContextData> => {
  const currentBusinessContextData = getBusinessContextData();
  const requiredContext = tryAdjustContextToUnitIdentifier(
    currentBusinessContextData,
    unit
  );

  if (requiredContext) {
    return requiredContext;
  }

  const cachedContext = businessContextDataCache.getItem(unit);
  if (cachedContext) {
    return cachedContext;
  }

  const newBusinessContextData = await fetchBusinessContextData(
    unit,
    currentBusinessContextData
  );

  businessContextDataCache.addItem(newBusinessContextData);

  return newBusinessContextData;
};

const fetchBusinessContextData = async (
  unit: BusinessContextUnitIdentifier,
  currentContextData?: SepModuleBusinessContextData
): Promise<SepModuleBusinessContextData> => {
  const loader = new SepModuleBusinessContextLoader();

  if (!currentContextData) {
    return loader.load(unit);
  }

  return loader.loadForDifferentScope(currentContextData, unit);
};
