import { useTranslation } from 'react-i18next';

import './TrainingBar.scss';

export const TrainingBar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container-training-bar">{t('TRAINING_ENVIRONMENT')}</div>
  );
};
