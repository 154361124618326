import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';
import {
  DATE_FORMAT,
  FormattedDate,
  FormattedTime,
  getCurrentPropertyDateTime,
  getFormattedBusinessDate,
  getFormattedTime,
} from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/BusinessDate/service';
import {
  propertyBusinessDateUpdatedEventCreator,
  propertyLocalDateTimeUpdatedEventCreator,
} from 'frontend-container/publicApi';
import { updatePropertyBusinessDate } from 'frontend-container/shared/businessContext/mutators';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import { withCancellation } from './withCancellation';

export const useBusinessDateTime: DateTimeHook = () => {
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const [dateFormat, setDateFormat] = useState<string>(DATE_FORMAT);

  const eventBus = useMemo(() => getCurrentGlobalEventBus(), []);

  const updatePropertyTime = useCallback(
    ({ time: timeValue, currentDateTime }: FormattedTime) => {
      const { localPropertyDateTime } = currentDateTime;

      setTime(timeValue);

      eventBus.dispatch(
        propertyLocalDateTimeUpdatedEventCreator({ localPropertyDateTime })
      );
    },
    [eventBus]
  );

  const updateBusinessDate = useCallback(
    async ({ date: dateValue, dateFormat: dateFormatValue }: FormattedDate) => {
      const { localPropertyDateTime } = await getCurrentPropertyDateTime();

      setDate(dateValue);
      setDateFormat(dateFormatValue);

      // todo: the local date cannot be treated as a businessDate!
      eventBus.dispatch(
        propertyBusinessDateUpdatedEventCreator({
          businessDate: localPropertyDateTime,
        })
      );
    },
    [eventBus]
  );

  useEffect(() => {
    const refreshBusinessDate = async (): Promise<void> => {
      await updatePropertyBusinessDate();
      getDate();
    };

    const { cancel: cancelLoadDate, invoke: getDate } = withCancellation({
      beforeCancel: getFormattedBusinessDate,
      partWhichCanBeCancelled: updateBusinessDate,
    });
    const { cancel: cancelLoadTime, invoke: getTime } = withCancellation({
      beforeCancel: getFormattedTime,
      partWhichCanBeCancelled: updatePropertyTime,
    });

    getDate();
    getTime();

    const timeInterval = setInterval(() => getTime(), 60000);
    const dateInterval = setInterval(() => getDate(), 300000);

    (
      window.ACP?.container ?? { refreshBusinessDate: undefined }
    ).refreshBusinessDate = refreshBusinessDate;

    return (): void => {
      cancelLoadDate();
      cancelLoadTime();
      clearInterval(timeInterval);
      clearInterval(dateInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    date,
    dateFormat,
    time,
    timeFormat: undefined,
  };
};
