import { setIdleTimeout } from 'frontend-container/components/AutomaticLogout/idleTimeout';
import {
  fetchCashierData,
  getProcessedCashierPermissions,
} from 'frontend-container/components/Menu/components/Cashier/service';
import { getCurrentPropertyRegionCode } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { formatError } from 'frontend-container/utils/errors/formatError';
import { getCurrentUrl } from 'frontend-container/utils/location';
import { logger } from 'frontend-container/utils/logger/logger';
import { storeURLDuringLogout } from 'frontend-container/utils/loginService/logoutService';

import { getCustomConfigWithPropertyRegionSpecificHost } from '@ac/library-api';
import { CashierApi } from '@ac/library-api/dist/api/v0/cashiering';
import {
  LoginService,
  SessionDataHost,
  SessionService,
} from '@ac/library-utils/dist/services';

export const clearStorageData = (): void => {
  SessionDataHost.clearSessionData();
  setIdleTimeout(null);
};

export const logout = async (
  useCurrentLocation: boolean = true,
  withPostLogoutPage: boolean = true
): Promise<void> => {
  const cashierPermissions = getProcessedCashierPermissions();

  const isLogoutCashierAllowed =
    cashierPermissions.isOpenCashierAllowed &&
    cashierPermissions.isViewCashierStatusDashboard;

  logger?.information('Log out user - start');

  if (isLogoutCashierAllowed) {
    await logoutCashierInApi();
  }
  clearStorageData();
  await logoutUser(
    useCurrentLocation ? getCurrentUrl() : undefined,
    withPostLogoutPage
  );
};

const logoutUser = async (
  urlToRedirectAfterLogin?: string,
  withPostLogoutPage?: boolean
): Promise<void> => {
  if (urlToRedirectAfterLogin) {
    storeURLDuringLogout(urlToRedirectAfterLogin);
  }
  if (withPostLogoutPage) {
    LoginService.setPostLogoutRedirectUri(`${window.origin}/logout`);
  }

  await logger?.forceSend();
  void LoginService.logout();
};

const logoutCashierInApi = async (): Promise<void> => {
  if (!LoginService.authData()?.tenantId || !SessionService.getPropertyId()) {
    return;
  }

  const { isCashierOpen, cashierNumber } = await fetchCashierData();
  if (!isCashierOpen || !cashierNumber) {
    return;
  }

  const currentPropertyRegionCode = getCurrentPropertyRegionCode();

  try {
    await CashierApi.postUnregisterFromCashier({
      data: { cashierNumber },
      customConfig: {
        ...(currentPropertyRegionCode
          ? getCustomConfigWithPropertyRegionSpecificHost(
              currentPropertyRegionCode
            )
          : {}),
      },
    });
  } catch (error) {
    logger?.error(
      formatError(
        error,
        `Log out Cashier - unregister failed. cashierNumber: ${cashierNumber.toString()}. isCashierOpen: ${isCashierOpen.toString()}`
      )
    );
  }
};
