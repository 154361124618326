import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { hasAnyCroContextGuard } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';

import { GeneralCustomerSettingsKeys } from '@ac/library-api';
import { IconName } from '@ac/web-components';

export const applicationDashboardsMenu: MenuElement = {
  translation: 'MENU.APPLICATION.TITLE',
  icon: IconName.acProject,
  id: 'menu-main-application',
  items: [
    {
      link: propertyLandingPagePathname,
      translation: 'MENU.APPLICATION.ITEMS.PROPERTY_DASHBOARD',
    },
    {
      link: croLandingPagePathname,
      translation: 'MENU.APPLICATION.ITEMS.CRO_DASHBOARD',
      settings: [GeneralCustomerSettingsKeys.CentralReservationOffice],
      allowedWhen: (params) => hasAnyCroContextGuard(params),
    },
  ],
};
