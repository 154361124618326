import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  GeneralCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const resourceManagementPermissions = propertyPermissionKeys.resourceManagement;

export const resourceManagementMenu: MenuElement = {
  translation: 'MENU.RESOURCE_MANAGEMENT.TITLE',
  icon: IconName.concierge,
  id: 'menu-resource-management',
  settings: [GeneralCustomerSettingsKeys.StaffScheduling],
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/resource-management`,
      aliases: [acConfig.newFrontendUrls.configurationv2],
      permissionsConfiguration: {
        permissions: [
          resourceManagementPermissions.viewLeave,
          resourceManagementPermissions.viewMyDepartmentLeave,
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      translation: 'MENU.RESOURCE_MANAGEMENT.ITEMS.LEAVE_CALENDAR',
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/resource-management/resources`,
      aliases: [acConfig.newFrontendUrls.configurationv2],
      permissionsConfiguration: {
        permissions: [
          resourceManagementPermissions.viewStaff,
          resourceManagementPermissions.viewMyDepartmentStaff,
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      translation: 'MENU.RESOURCE_MANAGEMENT.ITEMS.RESOURCES',
    },
  ],
};
