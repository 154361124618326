import { businessContextUpdatedEventCreator } from 'frontend-container/publicApi';
import { businessContextDataCache } from 'frontend-container/shared/businessContext/cache';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import { BaseApi, UserPreferences } from '@ac/library-api';
import { UserPreferencesApi } from '@ac/library-api/dist/api/v0/configuration/users';

export const updateUserPreferences = async (): Promise<void> => {
  const container = window.ACP?.businessContext;
  const context = container?.context;

  if (!context || !container) {
    return;
  }

  BaseApi.clearCache(new RegExp(UserPreferencesApi.ApiCacheKeyUserPreferences));

  const userPreferences =
    (await UserPreferencesApi.getMyPreferences()) as UserPreferences;

  context.user.preferences = userPreferences;
  container.context = context;

  businessContextDataCache.clear();
  container.onUnitChanged?.(context);

  getCurrentGlobalEventBus().dispatch(businessContextUpdatedEventCreator());
};
