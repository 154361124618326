import { useEffect, useState } from 'react';

import {
  SessionDataChangedEventType,
  SessionService,
} from '@ac/library-utils/dist/services';

type SessionContextTypeIds = {
  propertyId: string | undefined;
  centralReservationOfficeId: string | undefined;
};

const getCurrentState = (): SessionContextTypeIds => {
  return {
    propertyId: SessionService.getPropertyId(),
    centralReservationOfficeId: SessionService.getCentralReservationOfficeId(),
  };
};

export const useSessionContextTypeIds = (): SessionContextTypeIds => {
  const [state, setState] = useState<SessionContextTypeIds>(getCurrentState);

  useEffect(() => {
    const sessionDataHandler = (): void => {
      setState(getCurrentState());
    };

    window.addEventListener(SessionDataChangedEventType, sessionDataHandler);

    return () => {
      window.removeEventListener(
        SessionDataChangedEventType,
        sessionDataHandler
      );
    };
  }, []);

  return state;
};
