import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  customerPermissionKeys,
  propertyPermissionKeys,
  systemPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const auditChangelogUrl = `${acConfig.newFrontendUrls.changelog}/changelog`;

export const changelogMenu: MenuElement = {
  translation: 'MENU.LOG.TITLE_WHEN_INTEGRATION_LOGGING_DASHBOARD_ENABLED',
  icon: IconName.accessHistory,
  id: 'menu-changelog',
  items: [
    {
      link: auditChangelogUrl,
      translation: 'MENU.LOG.ITEMS.CHANGELOG',
      permissionsConfiguration: {
        permissions: [propertyPermissionKeys.auditLog.propertyLevelAudit],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+h',
      keyboardShortcutMac: '⌥+⌘+h',
      keyCode: 72,
    },
    {
      link: `${acConfig.newFrontendUrls.changelog}/technical-events-log`,
      translation: 'MENU.LOG.ITEMS.TECHNICAL_EVENTS_LOG',
      permissionsConfiguration: {
        permissions: [
          propertyPermissionKeys.auditLog.propertyLevelAudit,
          customerPermissionKeys.auditLog.tenantLevelAuditLog,
          systemPermissionKeys.auditLog.systemLevelAuditLog,
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: 'alt+ctrl+.',
      keyboardShortcutMac: '⌥+⌘+.',
      keyCode: 190,
    },
    {
      link: `${acConfig.newFrontendUrls.changelog}/system-integration-logs`,
      translation: 'MENU.LOG.ITEMS.INTEGRATION_LOGS',
      permissionsConfiguration: {
        permissions: [
          propertyPermissionKeys.integrationLogs.retrievalPropertyLogs,
          customerPermissionKeys.integrationLogs.retrievalTenantLogs,
          systemPermissionKeys.integrationLogs.retrievalSystemLogs,
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: 'alt+ctrl+-',
      keyboardShortcutMac: '⌥+⌘+shift+-',
      keyCode: 189,
    },
  ],
};
