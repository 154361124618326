import { useEffect, useState } from 'react';
import { businessContextUpdatedEventCreator } from 'frontend-container/publicApi/events';
import {
  Branding,
  loadBranding,
} from 'frontend-container/shared/branding/loadBranding';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

export const useBranding = (): Branding | undefined => {
  const [branding, setBranding] = useState<Branding | undefined>();
  useEffect(() => {
    const eventBus = getCurrentGlobalEventBus();

    const load = async (): Promise<void> => {
      const loadBrandingResult = await loadBranding();

      if (loadBrandingResult) {
        setBranding(loadBrandingResult);
      }
    };

    load();

    const unsubscribeBusinessContextUpdatedEvent = eventBus.subscribe(
      businessContextUpdatedEventCreator,
      load
    );

    return () => unsubscribeBusinessContextUpdatedEvent();
  }, []);

  return branding;
};
