import {
  getBusinessContextData,
  getCustomerContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import { getUnitForCurrentLocation } from 'frontend-container/shared/businessContext/getUnitForCurrentLocation';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';

export const NEW_MENU_KEY = 'aboveCloud.newmenu';
export const THEME_DARK_KEY = 'aboveCloud.themeDark';

export const shouldUseNewMenu = (): boolean => {
  const useNewMenu = window.localStorage.getItem(NEW_MENU_KEY) ?? '';

  return Boolean(Number(useNewMenu));
};

export const shouldUseDarkTheme = (): boolean => {
  return LocalStorage.getParsed<boolean>(THEME_DARK_KEY) || false;
};

export const getIsNonProduction = (): boolean => {
  const customerContext = getCustomerContextData();

  return customerContext?.details?.nonProduction ?? false;
};

export const isBusinessContextDataGoingToChange = (
  pathname: string = window.location.pathname
): boolean => {
  if (isError403IpWhitlelistPage()) {
    return false;
  }

  const { id } = getUnitForCurrentLocation(pathname);
  const { unitId } = getBusinessContextData();

  return id !== unitId;
};
